import { ClassData } from '@models/class-data';
import { TicketFeeType } from '@models/tickets/ticket-fee-type.enum';
import { TicketLayout } from '@models/tickets/ticket-layout.model';
import { TicketSalesChannel } from '@models/tickets/ticket-sales-channel.enum';
import { TicketType } from '@models/tickets/ticket-type.enums';
import { NumberUtils } from '@utils/number-utils';
import { EventTicketDataField } from './event-ticket-data-field.model';
import { EventSlot } from './event-slot.model';
import moment from 'moment-timezone';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';

export class EventTicket {
  id?: number;
  eventId: number;
  type: TicketType;
  name: string;
  description: string;
  maxCapacity: number;
  feeType: TicketFeeType;
  price: number;
  saleStartDate?: Date | null;
  saleEndDate?: Date | null;

  day?: Date | null;

  visible: boolean;
  showSalesDeadlineAndStatusDuringOrder: boolean;
  minAmountPerOrder: number;
  maxAmountPerOrder: number;
  salesChannel: TicketSalesChannel;
  link: string;

  ticketPdfDescription: string;

  layoutId?: number;
  layout?: TicketLayout;

  landingPageDescription?: string;
  landingPageIcon?: string;

  accountRequired?: boolean;

  dataFields?: EventTicketDataField[];
  slots?: EventSlot[];

  numSoldTickets?: number;
  numLockedTickets?: number;

  constructor(json: ClassData<EventTicket>) {
    this.id = json.id;
    this.eventId = json.eventId;
    this.type = json.type;
    this.name = json.name;
    this.description = json.description;
    this.maxCapacity = json.maxCapacity;
    this.feeType = json.feeType;
    this.price = json.price;
    if (json.saleStartDate) {
      this.saleStartDate = new Date(json.saleStartDate);
    }
    if (json.saleEndDate) {
      this.saleEndDate = new Date(json.saleEndDate);
    }

    if (json.day) {
      this.day = new Date(json.day);
    }

    this.visible = json.visible;
    this.showSalesDeadlineAndStatusDuringOrder =
      json.showSalesDeadlineAndStatusDuringOrder;
    this.minAmountPerOrder = json.minAmountPerOrder;
    this.maxAmountPerOrder = json.maxAmountPerOrder;
    this.salesChannel = json.salesChannel;
    this.link = json.link;

    this.ticketPdfDescription = json.ticketPdfDescription;

    if (json.layoutId) {
      this.layoutId = +json.layoutId;
    }
    if (json.layout) {
      this.layout = new TicketLayout(json.layout);
    }

    this.landingPageDescription = json.landingPageDescription;
    this.landingPageIcon = json.landingPageIcon;

    if (json.accountRequired !== undefined) {
      this.accountRequired = json.accountRequired;
    }

    if (json.dataFields) {
      this.dataFields = json.dataFields.map(
        (dataField) => new EventTicketDataField(dataField),
      );
    }
    if (json.slots) {
      this.slots = json.slots
        .map((slot) => new EventSlot(slot))
        .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
    }

    this.numSoldTickets = json.numSoldTickets;
    this.numLockedTickets = json.numLockedTickets;
  }

  getTicketStatusDisplayName(): string {
    const now = new Date();

    if (
      this.maxCapacity &&
      this.numSoldTickets &&
      this.numSoldTickets >= this.maxCapacity
    ) {
      return 'APP.TICKETS_EDIT.SOLD_OUT';
    }

    if (this.saleStartDate && now < this.saleStartDate) {
      return 'APP.TICKETS_EDIT.COMING_SOON';
    } else if (this.saleEndDate && now > this.saleEndDate) {
      return 'APP.TICKETS_EDIT.SALE_ENDED';
    }

    return 'APP.TICKETS_EDIT.ON_SALE';
  }

  getPriceFormatted(): string {
    if (this.price === undefined) {
      return '';
    }
    return (this.price / 100.0).toFixed(2);
  }

  getDisplayPrice(): string {
    return `${NumberUtils.roundToUpTo2Decimals(this.price / 100.0)} €`;
  }

  getDisplayPriceKeepWhole(): string {
    return `${NumberUtils.roundToUpTo2DecimalsKeepWhole(this.price / 100.0)}€`;
  }

  getNumAvailableTickets(): number {
    let availableTickets = this.maxCapacity;

    if (this.numSoldTickets) {
      availableTickets -= this.numSoldTickets;
    }
    if (this.numLockedTickets) {
      availableTickets -= this.numLockedTickets;
    }

    return availableTickets ? availableTickets : 0;
  }

  getSlotsByDate(): Map<string, EventSlot[]> {
    const dateMap = new Map<string, EventSlot[]>();

    if (!this.slots) {
      return dateMap;
    }

    this.slots.forEach((slot) => {
      const dateKey = slot.getStartDateTzMoment().format('YYYY-MM-DD');

      if (!dateMap.has(dateKey)) {
        dateMap.set(dateKey, []);
      }

      dateMap.get(dateKey)?.push(slot);
    });

    return dateMap;
  }

  getDataFieldsSorted(): EventTicketDataField[] | undefined {
    return this.dataFields?.sort((a, b) => a.order - b.order);
  }

  getDataFieldsSortedAsEventAttendeeQuestion():
    | EventAttendeeQuestion[]
    | undefined {
    return this.dataFields
      ?.sort((a, b) => a.order - b.order)
      .filter((ticketDataField) => !!ticketDataField.dataField)
      .map((ticketDataField) => ticketDataField.getAsEventAttendeeQuestion()!);
  }
}
