import { ClassData } from '@models/class-data';
import { DataField } from '@models/data-fields/data-field.model';
import { EventAttendeeQuestionOption } from '@models/event-attendance/event-attendee-question-option.model';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';

export class EventTicketDataField {
  id: number;

  dataFieldId: number;
  dataField?: DataField;

  order: number;
  isRequired: boolean;

  constructor(json: ClassData<EventTicketDataField>) {
    this.id = +json.id;

    this.dataFieldId = +json.dataFieldId;
    if (json.dataField) {
      this.dataField = new DataField(json.dataField);
    }

    this.order = +json.order;
    this.isRequired = json.isRequired;
  }

  getAsEventAttendeeQuestion(): EventAttendeeQuestion | undefined {
    if (!this.dataField) {
      return undefined;
    }

    const options = this.dataField.options?.map((option) => {
      return new EventAttendeeQuestionOption({
        id: option.id,

        eventAttendeeQuestionId: option.dataFieldId,

        label: option.label,
        order: option.order,
      });
    });

    return new EventAttendeeQuestion({
      id: this.dataFieldId,

      eventTemplateId: 0,

      type: this.dataField.type,

      label: this.dataField.label,

      order: this.order,
      isRequired: this.isRequired,

      showInGuestList: true,
      isRsvpDataField: true,

      options: options,
    });
  }
}
