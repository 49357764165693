import { ClassData } from '@models/class-data';

export class DataFieldOption {
  id: number;

  dataFieldId?: number;

  label: string;
  order: number;

  constructor(json: ClassData<DataFieldOption>) {
    this.id = +json.id;

    this.dataFieldId = json.dataFieldId;

    this.label = json.label;
    this.order = +json.order;
  }
}
