import moment, { Moment } from 'moment-timezone';
import { NumberUtils } from './number-utils';
import { TimezoneOption } from '@models/timezone/timezone-option.model';
import { TranslateService } from '@ngx-translate/core';

export class DateUtils {
  static getDateRangeAtTimezone(
    startDate: Date | undefined,
    endDate: Date | undefined,
    startTime: string,
    endTime: string,
    timeZone: string,
  ): { startDate?: Date; endDate?: Date } {
    if (!timeZone || timeZone === '') {
      timeZone = moment.tz.guess();
    }

    let startDateFull = null;
    let endDateFull = null;

    if (startDate) {
      const dateOnlyString = this.dateOnlyToString(startDate);

      if (!startTime) {
        startTime = '00:00';
      }

      startDateFull = moment.tz(`${dateOnlyString} ${startTime}`, timeZone);
    }
    if (!endDate && endTime && endTime !== '') {
      endDate = startDate;
    }
    if (endDate) {
      const dateOnlyString = this.dateOnlyToString(endDate);

      if (!endTime) {
        endTime = '00:00';
      }

      endDateFull = moment.tz(`${dateOnlyString} ${endTime}`, timeZone);
    }

    return {
      startDate: startDateFull?.toDate(),
      endDate: endDateFull?.toDate(),
    };
  }

  static isSameDate(date1: string | Date, date2: Date): boolean {
    const d1 = this.formatToDateOnly(date1);
    const d2 = this.formatToDateOnly(date2);
    return d1 === d2;
  }

  static formatToDateOnly(date: string | Date): string {
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // Extract YYYY-MM-DD
  }

  static combineDateAndTime(
    date: Date | null,
    time: string | null,
  ): Date | null {
    if (!date) return null;

    const dateObj = new Date(date);

    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      dateObj.setHours(hours);
      dateObj.setMinutes(minutes);
    }
    return dateObj;
  }

  static dateOnlyToString(date: Date): string | null {
    if (!date) {
      return null;
    }

    const monthNumber = date.getMonth() + 1;
    const month = NumberUtils.padStart(monthNumber, 2, '0');

    const dayNumber = date.getDate();
    const day = NumberUtils.padStart(dayNumber, 2, '0');

    return `${date.getFullYear()}-${month}-${day}`;
  }

  static getUserTimezoneOption(): TimezoneOption {
    const timezone = moment.tz.guess();
    const offset = moment.tz(timezone).utcOffset();

    return new TimezoneOption({
      name: timezone,
      offset: offset,
    });
  }

  static formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  static getDateAfterBusinessDays(businessDays: number): Moment {
    const currentDate = moment();

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate;
  }

  static isAtLeastXBusinessDaysFuture(date: Date, businessDays: number) {
    const currentDate = moment();
    const futureDate = moment(date);

    if (futureDate.isSameOrBefore(currentDate)) {
      return false;
    }

    let businessDaysCount = 0;
    while (businessDaysCount < businessDays) {
      currentDate.add(1, 'day');
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        businessDaysCount++;
      }
    }

    return currentDate.isSameOrBefore(futureDate, 'day');
  }

  static formatDateDay(date: Date, currentLang?: string) {
    const day = date.getDay();
    switch (day) {
      case 1:
        return currentLang === 'en' ? 'Monday' : 'Montag';
      case 2:
        return currentLang === 'en' ? 'Tuesday' : 'Dienstag';
      case 3:
        return currentLang === 'en' ? 'Wednesday' : 'Mittwoch';
      case 4:
        return currentLang === 'en' ? 'Thursday' : 'Donnerstag';
      case 5:
        return currentLang === 'en' ? 'Friday' : 'Freitag';
      case 6:
        return currentLang === 'en' ? 'Saturday' : 'Samstag';
      default:
        return currentLang === 'en' ? 'Sunday' : 'Sonntag';
    }
  }

  static formatDateMonth(date: Date, currentLang?: string) {
    if (currentLang) {
      return moment(date).locale(currentLang).format('MMMM');
    }

    return moment(date).format('MMMM');
  }

  static getTimezoneDisplay(timezone: string): string | undefined {
    return `GMT${this.getOffsetDisplay(moment.tz(timezone).utcOffset())}`;
  }

  static getOffsetDisplay(utcOffset: number): string {
    let utcOffsetHours = (utcOffset / 60).toString();

    if (utcOffsetHours[0] !== '-') {
      utcOffsetHours = '+' + utcOffsetHours;
    }

    return utcOffsetHours;
  }

  static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static isDateInThePast(dateTime: Date | string): boolean {
    const inputDateTime = new Date(dateTime);
    const now = new Date();

    return inputDateTime < now;
  }

  static isDateInTheFuture(dateTime: Date | string): boolean {
    const inputDateTime = new Date(dateTime);
    const now = new Date();

    return inputDateTime > now;
  }

  static getLocalizedDurationInfo(
    startDate: string | Date,
    endDate: string | Date,
    translate: TranslateService,
    allDay: boolean = false,
  ): string {
    const start = moment(startDate);
    const end = moment(endDate);
    const now = moment();

    let durationStr = '';

    if (allDay) {
      durationStr = translate.instant('APP.DURATION_INFO.DURATION_ALL_DAY');
    } else {
      const duration = moment.duration(end.diff(start));
      if (duration.asHours() >= 1) {
        const hours = Math.floor(duration.asHours());
        const label = translate.instant(
          hours === 1
            ? 'APP.DURATION_INFO.DURATION_HOUR'
            : 'APP.DURATION_INFO.DURATION_HOURS',
        );
        durationStr = `${hours} ${label}`;
      } else {
        const minutes = Math.floor(duration.asMinutes());
        const label = translate.instant(
          minutes === 1
            ? 'APP.DURATION_INFO.DURATION_MINUTE'
            : 'APP.DURATION_INFO.DURATION_MINUTES',
        );
        durationStr = `${minutes} ${label}`;
      }
    }

    // If already started, return only duration
    if (now.isAfter(start)) {
      return durationStr;
    }

    // Until start string
    let untilStartStr = '';
    const untilStart = moment.duration(start.diff(now));
    if (untilStart.asDays() >= 1) {
      const days = Math.floor(untilStart.asDays());
      const key =
        days === 1
          ? 'APP.DURATION_INFO.STARTS_IN_DAY'
          : 'APP.DURATION_INFO.STARTS_IN_DAYS';
      untilStartStr = translate.instant(key, { count: days });
    } else if (untilStart.asHours() >= 1) {
      const hours = Math.floor(untilStart.asHours());
      const key =
        hours === 1
          ? 'APP.DURATION_INFO.STARTS_IN_HOUR'
          : 'APP.DURATION_INFO.STARTS_IN_HOURS';
      untilStartStr = translate.instant(key, { count: hours });
    } else {
      const minutes = Math.max(1, Math.floor(untilStart.asMinutes()));
      const key =
        minutes === 1
          ? 'APP.DURATION_INFO.STARTS_IN_MINUTE'
          : 'APP.DURATION_INFO.STARTS_IN_MINUTES';
      untilStartStr = translate.instant(key, { count: minutes });
    }

    return `${durationStr}, ${untilStartStr}`;
  }

  static formatDateRange(startDate: Date, endDate?: Date): string {
    const pad = (n: number) => n.toString().padStart(2, '0');

    const format = (date: Date) =>
      `${pad(date.getDate())}.${pad(
        date.getMonth() + 1,
      )}.${date.getFullYear()}`;

    if (!endDate) {
      return format(startDate);
    }

    const sameYear = startDate.getFullYear() === endDate.getFullYear();
    const sameMonth = startDate.getMonth() === endDate.getMonth();

    if (sameYear && sameMonth) {
      return `${pad(startDate.getDate())} - ${format(endDate)}`;
    }

    if (sameYear) {
      return `${pad(startDate.getDate())}.${pad(
        startDate.getMonth() + 1,
      )} - ${pad(endDate.getDate())}.${pad(
        endDate.getMonth() + 1,
      )}.${startDate.getFullYear()}`;
    }

    return `${format(startDate)} - ${format(endDate)}`;
  }
}
