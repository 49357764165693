import { ClassData } from '@models/class-data';
import { DateUtils } from '@utils/date-utils';
import moment, { Moment } from 'moment-timezone';

export class EventSlot {
  id: number;

  eventId: number;
  eventTicketId?: number;

  startDate: Date;
  endDate: Date;
  timeZone: string;
  allDay: boolean;

  capacity?: number;

  numSoldTickets?: number;
  numLockedTickets?: number;

  constructor(json: ClassData<EventSlot>) {
    this.id = +json.id;

    this.eventId = +json.eventId;
    if (json.eventTicketId) {
      this.eventTicketId = +json.eventTicketId;
    }

    this.startDate = new Date(json.startDate);
    this.endDate = new Date(json.endDate);
    this.timeZone = json.timeZone;
    this.allDay = json.allDay;

    if (json.capacity) {
      this.capacity = +json.capacity;
    }

    if (json.numSoldTickets !== undefined) {
      this.numSoldTickets = +json.numSoldTickets;
    }
    if (json.numLockedTickets !== undefined) {
      this.numLockedTickets = +json.numLockedTickets;
    }
  }

  getNumAvailableTickets(): number {
    if (!this.capacity) {
      return 0;
    }

    let availableTickets = this.capacity;

    if (this.numSoldTickets) {
      availableTickets -= this.numSoldTickets;
    }
    if (this.numLockedTickets) {
      availableTickets -= this.numLockedTickets;
    }

    return availableTickets ? availableTickets : 0;
  }

  getTimeRangeFormatted(): string {
    if (this.allDay) {
      return 'APP.SPACES.BOOKINGS.ALL_DAY';
    }
    return (
      moment(this.startDate).format('HH:mm') +
      '-' +
      moment(this.endDate).format('HH:mm')
    );
  }

  getStartDateTzMoment(): Moment {
    return moment.tz(this.startDate, this.timeZone);
  }

  getEndDateTzMoment(): Moment {
    return moment.tz(this.endDate, this.timeZone);
  }

  getSlotDateDisplay(currentLang: string | undefined): string {
    if (!this.startDate) {
      return '';
    }
    let dateInfo =
      DateUtils.formatDateDay(this.startDate, currentLang) +
      ', ' +
      moment(this.startDate).format('DD.MM.YYYY, HH:mm');

    if (this.endDate && this.endsOnSameDate()) {
      dateInfo += `-${moment(this.endDate).format('HH:mm')}`;
    }

    return dateInfo;
  }

  endsOnSameDate(): boolean {
    if (!this.endDate) {
      return false;
    }

    return moment(this.startDate).isSame(this.endDate, 'day');
  }
}
