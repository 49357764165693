import { ClassData } from '@models/class-data';
import { QuestionType } from '@models/event-attendance/event-attendee-question-type.enum';
import { DataFieldOption } from './data-field-option.model';

export class DataField {
  id: number;

  type: QuestionType;

  label: string;

  options?: DataFieldOption[];

  constructor(json: ClassData<DataField>) {
    this.id = +json.id;

    this.type = json.type;

    this.label = json.label;

    if (json.options) {
      this.options = this.mapOptions(json.options);
    }
  }

  private mapOptions(options: DataFieldOption[]): DataFieldOption[] {
    return options.map((it) => new DataFieldOption(it));
  }
}
